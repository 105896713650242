<template>
  <div class="loader-container" v-if="isLoading">
    <span class="loader"></span>
  </div>
  <div id="chart" class="chart" v-else>
    <apexchart
      type="line"
      height="450"
      :options="evolutionChartOptions.options"
      :series="evolutionChartOptions.series"></apexchart>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from 'vue';
import { formatCurrencyWithoutSymbol, getMonth } from '@/mixins';
import { ManagerDashboardController } from '@/AppClubCarby/Controller/DashBoardsController';

const isLoading = ref(true);

const managerDashboardService = new ManagerDashboardController(
  props.managerId,
  props.periodStart,
  props.periodEnd,
  props.gasStationId
);

const props = defineProps({
  managerId: {
    type: Number,
    required: true,
  },
  periodStart: {
    type: String,
    required: true,
  },
  periodEnd: {
    type: String,
    required: true,
  },
  gasStationId: {
    type: Number,
    required: true,
  },
});

const chartData = ref([]);

const evolutionChartOptions = ref({
  series: [
    {
      name: 'Cadastros',
      data: [],
    },
    {
      name: 'Abastecimentos',
      data: [],
    },
  ],
  options: {
    chart: {
      type: 'line',

      toolbar: {
        show: false,
      },
    },
    colors: ['#FFC145', '#4A8A2E'],
    dataLabels: {
      enabled: true,
      offsetX: -3,
      style: {
        fontSize: '10px',
      },
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: 'string',
      categories: [],
      labels: {
        rotateAlways: true,
        rotate: -90,
        maxHeight: 120,
        hideOverlappingLabels: true,
        style: {
          fontSize: '10px',
        },
      },
      tickAmount: 15,
    },
    yaxis: {
      title: {
        text: '',
      },
      min: 0,
      forceNiceScale: true,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          title: {
            style: {
              fontSize: '14px',
              color: '#11592E',
            },
          },
        },
      },
    ],
  },
  setSeries(index, data) {
    this.series[index].data = data;
  },
  set setCategories(data) {
    this.options.xaxis.categories = data;
  },
});

const updateEvolutionChart = () => {
  const registrations =
    chartData.value.length > 0 ? chartData.value.map((data) => data.value) : 0;

  const dateSupplies = chartData.value.map((data) => {
    let arrSplit = data.startDate.split('/');
    let dayMonth = `${arrSplit[0]}/${arrSplit[1]}`;
    return dayMonth;
  });

  evolutionChartOptions.value.setSeries(0, registrations);
  evolutionChartOptions.value.setCategories = dateSupplies;
};

onMounted(async () => {
  getData();
});

const getData = async () => {
  chartData.value = await managerDashboardService.getClientEvolutionChartData();
  updateEvolutionChart();
  isLoading.value = false;
};

watch(props, (newVal, oldVal) => {
  if (newVal.toString() != oldVal.toString()) {
    getData();
  }
});
</script>

<style scoped>
.loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #11592e;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
